import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import { useAppSelector } from '../../../store/hooks';
import { selectUserId } from '../../../store/reducers/authReducers';
import type { OnlyfyData } from '../../../types';
import { Button, Icon } from '../../atoms';
import { Input } from '../../molecules';
import { NavigationDrawer } from '../../organisms';
import style from './Einstellungen.module.scss';

const cx = classNames.bind(style);

export function Einstellungen(): JSX.Element {
  const navigate = useNavigate();
  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses} test-id='einstellungen-header'>
          <Icon icon='account-plus' size={70}></Icon>
          <span>
            <h1>Einstellungen</h1>
          </span>
        </div>

        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 0.5,
              backgroundColor: '#fff',
              marginRight: '1rem',
              padding: '15px 15px',
              borderRadius: '4px',
              boxShadow: '2px 2px 4px 0px #d9d9d9',
            }}
          >
            <div>
              <h2 style={{ marginBottom: '1.5rem' }}>Onlyfy-Daten</h2>
            </div>

            <div style={{ marginTop: '3rem', width: '20rem' }}>
              <Button type='primary' onClick={() => navigate(`/einstellungen/onlyfy`)}>
                Zu den Onlyfy-Einstellungen
              </Button>
            </div>
          </div>
          {/**
             <div
             style={{
             flex: 1,
             backgroundColor: '#fff',
             marginRight: '1rem',
             padding: '15px 15px',
             borderRadius: '4px',
             boxShadow: '2px 2px 4px 0px #d9d9d9',
             }}
             >
             <div>
             <h2 style={{ marginBottom: '1.5rem' }}>Passwort ändern</h2>
             </div>

             <div>
             <div
             style={{
             margin: '1rem 1rem 1rem 0',
             fontSize: '1.6rem',
             fontWeight: '400',
             color: '#666666',
             }}
             >
             Altes Passwort
             </div>
             <div>
             <Input
             onChange={(event) => console.log(event.target.value)}
             icon='magnify'
             type='password'
             placeholder='Altes Passwort'
             ></Input>
             </div>
             </div>

             <div style={{ marginTop: '3rem' }}>
             <div
             style={{
             margin: '1rem 1rem 1rem 0',
             fontSize: '1.6rem',
             fontWeight: '400',
             color: '#666666',
             }}
             >
             Passwort
             </div>
             <div>
             <Input
             onChange={(event) => console.log(event.target.value)}
             icon='magnify'
             type='password'
             placeholder='Passwort'
             ></Input>
             </div>
             </div>
             <div style={{ marginTop: '3rem' }}>
             <div
             style={{
             margin: '1rem 1rem 1rem 0',
             fontSize: '1.6rem',
             fontWeight: '400',
             color: '#666666',
             }}
             >
             Passwort wiederholen
             </div>
             <div>
             <Input
             onChange={(event) => console.log(event.target.value)}
             icon='magnify'
             type='password'
             placeholder='Passwort wiederholen'
             ></Input>
             </div>
             </div>

             <div style={{ marginTop: '3rem', width: '20rem' }}>
             <Button type='primary'>Speichern</Button>
             </div>
             </div>
             */}
        </div>
      </div>
    </div>
  );
}
