import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
};

export class SuchenService {
  static async getAllSuchen(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/search/get_all_searches', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getSucheById(id: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/search/get_search_by_id?id=${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async reopenSearch(id: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          `/search/reopen_search`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async finishSearch(id: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          `/search/finishSearch?searchId=${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
