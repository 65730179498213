import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { CandidateService } from '../../../services';
import type { Candiate } from '../../../types';
import { Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import { CandidateList } from '../../organisms/CandidateList/CandidateList';
import style from './Kandidaten.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Kandidaten(): JSX.Element {
  const [candiates, setCandiates] = useState<Candiate[]>([]);

  const getAllCandidates = () => {
    CandidateService.getAllCandidates().then((response) => {
      if (response.error) {
        toast.error('Kandidaten konnten nicht geladen werden!');

        return;
      }
      setCandiates(response.data);
    });
  };

  useEffect(() => {
    getAllCandidates();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const boxHeaderClasses = cx({ BoxHeader: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='kandidaten-header'>
            <Icon icon='magnify' size={70}></Icon>
            <span>
              <h1>Kandidaten</h1>
              <div>Anzahl Kandidaten: {candiates?.length}</div>
            </span>
          </div>

          <div style={{ height: '100%' }}>
            <h2 className={boxHeaderClasses} test-id='kandidaten-subheader-candidates'>
              Kandidaten
            </h2>

            <CandidateList candidates={candiates} />
          </div>
        </div>
      </div>
    </>
  );
}
