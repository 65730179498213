import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
};

export class MessageService {
  static async sendMessageRequest(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/sendMessage/create_send_request', data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getOnlyfyMessagesPerMonth(onlyfyId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/message/onlyfy_user_messages_per_month?onlyfyUserId=${onlyfyId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getUnreadMessages(userId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/message/get_unread_messages?userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async markCandidateMessagesAsRead(candidateId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(
          `/message/setCandidateMessagesRead?candidateId=${candidateId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getOpenSendRequests(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/sendMessage/get_open_send_requests`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
