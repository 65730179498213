import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import Logo from '../../../assets/images/logo.png';
import { UserService } from '../../../services';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login, logout, selectIsAuthenticated } from '../../../store/reducers';
import { Button } from '../../atoms';
import { Input } from '../../molecules';
import style from './Login.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Login(): JSX.Element {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('jwt');
    const name = sessionStorage.getItem('name');
    const isAdminString = sessionStorage.getItem('admin');
    const isAdmin = atob(isAdminString || '') === 'true';
    const userIdString = sessionStorage.getItem('userId');
    const userId = atob(userIdString || '');

    if (token && name) {
      dispatch(login({ user: { name }, token, isAuthenticated: true, isAdmin, userId: Number(userId) }));
    }

    if (isAuthenticated || (token && name)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, []);

  const handleLogin = async (): Promise<void> => {
    const authData = {
      username,
      password,
    };
    const loginResponse = await UserService.login(authData);
    if (loginResponse.error) {
      console.log(loginResponse);
      if (
        loginResponse.message.response.data.error === 'email' ||
        loginResponse.message.response.data.error === 'password'
      ) {
        toast.error('Logindaten sind fehlerhaft.');
      } else {
        toast.error('Etwas ist schief gelaufen.');
      }
    }

    if (!loginResponse.error) {
      const userData = {
        name: username,
      };
      sessionStorage.setItem('name', username);
      sessionStorage.setItem('jwt', loginResponse.data.token);
      sessionStorage.setItem('admin', btoa(loginResponse.data.admin.toString()));
      sessionStorage.setItem('userId', btoa(loginResponse.data.userId.toString()));
      dispatch(
        login({
          user: userData,
          token: loginResponse.data.token,
          isAuthenticated: true,
          isAdmin: loginResponse.data.admin,
          userId: loginResponse.data.userId,
        })
      );
      setUsername('');
      setPassword('');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleLogin();
  };
  const logoutButton = (): void => {
    dispatch(logout());
  };

  const LoginPageClasses = cx({ Login: true });
  const LoginBoxClasses = cx({ LoginBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={LoginPageClasses}>
      <div className={LoginBoxClasses}>
        <div className={LogoContainerClasses}>
          <img src={Logo} alt='FourEnergy Logo' width={200} />
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            onChange={(event) => setUsername(event.target.value)}
            className={InputClasses}
            type='text'
            icon='magnify'
            placeholder='Username...'
            size={15}
            testId='login-input-username'
          />
          <Input
            onChange={(event) => setPassword(event.target.value)}
            className={InputClasses}
            type='password'
            icon='magnify'
            placeholder='Passwort...'
            size={15}
            testId='login-input-password'
          />
          <Button className={ButtonClasses} type='primary' onClick={handleLogin} testId='login-button-login'>
            Login
          </Button>
          <Button type='secondary' onClick={logoutButton} testId='login-button-forgot-password'>
            Passwort vergessen?
          </Button>
        </form>
      </div>
    </div>
  );
}
