import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import { useAppSelector } from '../../../store/hooks';
import { selectUserId } from '../../../store/reducers/authReducers';
import type { User } from '../../../types';
import { Button, Icon } from '../../atoms';
import { Input } from '../../molecules';
import { NavigationDrawer } from '../../organisms';
import style from './User.module.scss';

const cx = classNames.bind(style);

export function User(): JSX.Element {
  const userId = useAppSelector(selectUserId);
  const [user, setUser] = useState<User[]>([]);
  const [editUser, setEditUser] = useState<User | undefined>(undefined);
  const [newUser, setNewUser] = useState<User | undefined>(undefined);

  const getAllUser = () => {
    if (userId) {
      UserService.getAllUser(userId).then((response) => {
        if (response.error) {
          console.log(response);
          toast.error('User konnten nicht geladen werden!');

          return;
        }
        setUser(response.data);
      });
    }
  };

  const openCreateUser = () => {
    setNewUser({ username: '', admin: false, password: '' });
  };

  const saveNewUser = async () => {
    if (newUser && newUser.username && newUser.password) {
      const newUserResponse = await UserService.register(newUser);
      if (newUserResponse.error) {
        console.log(newUserResponse);
        toast.error('User konnte nicht erstellt werden!');

        return;
      }
      setNewUser(undefined);
      toast.success('User erfolgreich erstellt!');
      getAllUser();
    } else {
      toast.error('Username und Passwort muss ausgefüllt sein');
    }
  };

  const abortNewUser = () => {
    setNewUser(undefined);
  };

  const deleteUser = (userEntry: User) => {
    if (userId && userEntry.id) {
      UserService.delete(userId, userEntry.id).then((response) => {
        if (response.error) {
          console.log(response);
          toast.error('User konnte nicht gelöscht werden!');

          return;
        }
        getAllUser();
        toast.success('User erfolgreich gelöscht!');
      });
    }
  };

  const openUserEdit = (userEntry: User) => {
    setEditUser(userEntry);
  };

  const saveEditUser = () => {
    if (userId && editUser?.id && editUser?.admin) {
      UserService.changeRights(userId, editUser.id, editUser.admin).then((response) => {
        if (response.error) {
          console.log(response);
          toast.error('User konnte nicht bearbeitet werden!');

          return;
        }
        toast.success('User erfolgreich bearbeitet!');
        setEditUser(undefined);
      });
    }
  };

  const abortEditUser = () => {
    setEditUser(undefined);
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');

  return (
    <>
      {newUser && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '30%',
              borderRadius: '4px',
              padding: '20px',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}> User erstellen</div>
            <div style={{ marginTop: '2rem' }}>
              <div>Username:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='text'
                  placeholder='Username'
                  onChange={(event) => {
                    newUser.username = event.target.value;
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <div>Passwort:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='password'
                  placeholder='Password'
                  onChange={(event) => {
                    newUser.password = event.target.value;
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                <input
                  type={'checkbox'}
                  name='editUser'
                  onChange={(event) => {
                    newUser.admin = event.target.checked;
                  }}
                />
                <label htmlFor='editUser' style={{ marginLeft: '5px' }}>
                  Admin
                </label>
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Button type='primary' onClick={saveNewUser}>
                Speichern
              </Button>
              <Button type='secondary' onClick={abortNewUser}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}
      {editUser && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '30%',
              borderRadius: '4px',
              padding: '20px',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}> User bearbeiten</div>
            <div style={{ marginTop: '2rem' }}>
              <div>Username:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='text'
                  placeholder='Username'
                  onChange={(event) => {
                    editUser.username = event.target.value;
                  }}
                  defaultValue={editUser.username}
                  disabled={true}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                <input
                  type={'checkbox'}
                  name='editUser'
                  onChange={() => {
                    editUser.admin = !editUser.admin;
                  }}
                  defaultChecked={editUser.admin}
                />
                <label htmlFor='editUser' style={{ marginLeft: '5px' }}>
                  Admin
                </label>
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Button type='primary' onClick={saveEditUser}>
                Speichern
              </Button>
              <Button type='secondary' onClick={abortEditUser}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='user-header'>
            <Icon icon='account-plus' size={70}></Icon>
            <span>
              <h1>User</h1>
              <div>Anzahl User: {user.length}</div>
            </span>
          </div>
          <div style={{ width: '20rem' }}>
            <Button type='primary' onClick={openCreateUser}>
              Neuen User erstellen
            </Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            {user.map((u) => (
              <div
                key={u.id}
                test-id={`user-entry-${u.id}`}
                style={{
                  width: `${(1 / 3) * 100 - 2}%`,
                  padding: '5px 5px',
                  margin: '10px 5px 10px 0',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
                  <span style={{ fontWeight: 'bold' }}>{u.username}</span>
                  {u.id !== userId && (
                    <span>
                      <span style={{ cursor: 'pointer' }} onClick={() => openUserEdit(u)}>
                        ✏️
                      </span>
                      <span style={{ cursor: 'pointer', marginLeft: '1rem' }} onClick={() => deleteUser(u)}>
                        🗑️
                      </span>
                    </span>
                  )}
                </div>
                <div style={{ height: '12px', color: '#7f7f7f', marginTop: '4px' }}>{u.admin ? 'Admin' : ''}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
