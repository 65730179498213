import axios from 'axios';
import type { OnlyfyData, User } from '../types';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
};

export class UserService {
  static async login(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/authenticate', { ...data })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async register(data: User): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          '/user/register',
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async delete(editorId: number, userId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .delete(`/user/delete_user?editorId=${editorId}&userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async changeRights(editorId: number, userId: number, admin: boolean): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          '/user/change_rights',
          {
            editorId,
            userId,
            admin,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getOnlyfyData(userId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/user/get_onlyfy_data?userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async updateOnlyfyData(data: OnlyfyData & { onlyfyDataId?: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          '/user/update_onlyfy_data',
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async createOnlyfyData(data: OnlyfyData): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(
          '/user/add_onlyfy_data',
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllUser(userId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/user/get_all_users?userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
