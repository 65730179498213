import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  token: string | undefined;
  user: { name: string } | undefined;
  userId: number | undefined;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAdmin: false,
  token: undefined,
  user: undefined,
  userId: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthState>) => {
      const { token, user, isAdmin = false, userId } = action.payload;
      /* eslint-disable */
            state.isAuthenticated = true;
            state.isAdmin = isAdmin;
            state.token = token;
            state.user = user;
            state.userId = userId;
            /* eslint-enable */
    },
    logout: (state) => {
      /* eslint-disable */
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.token = undefined;
            state.user = undefined;
            state.userId = undefined;
            /* eslint-enable */
    },
  },
});

export const { login, logout } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsAdmin = (state: RootState) => state.auth.isAdmin;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectUserId = (state: RootState) => state.auth.userId;
