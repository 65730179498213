import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import Logo from '../../../assets/images/logo.png';
import { useAppSelector } from '../../../store/hooks';
import { selectIsAdmin } from '../../../store/reducers';
import { NavigationItem } from '../../molecules';
import style from './NavigationDrawer.module.scss';

const cx = classNames.bind(style);

export function NavigationDrawer(): JSX.Element {
  const isAuthenticated = useAppSelector(selectIsAdmin);

  const location = useLocation();
  const paths = location.pathname.split('/');

  const containerClasses = cx({ NavigationDrawerContainer: true });
  const itemsClasses = cx({ NavigationDrawerItems: true });
  const spacerClasses = cx({ NavigationDrawerSpacer: true });

  return (
    <div className={containerClasses}>
      <img src={Logo} alt='FourEnergy Logo' width={220} />
      <div className={itemsClasses}>
        <NavigationItem link={'/dashboard'} icon={'grid'} active={paths[1] === 'dashboard'}>
          Dashboard
        </NavigationItem>
        <div className={spacerClasses} />
        <NavigationItem link={'/suchen'} icon={'magnify'} active={paths[1] === 'suchen'}>
          Suchen
        </NavigationItem>
        <NavigationItem link={'/projekte'} icon={'magnify'} active={paths[1] === 'projekte'}>
          Projekte
        </NavigationItem>
        <NavigationItem link={'/nachrichten'} icon={'document'} active={paths[1] === 'nachrichten'}>
          Nachrichten
        </NavigationItem>
        <NavigationItem link={'/nachrichtenauftraege'} icon={'document'} active={paths[1] === 'nachrichtenauftraege'}>
          Nachrichtenaufträge
        </NavigationItem>
        <NavigationItem link={'/kandidaten'} icon={'user'} active={paths[1] === 'kandidaten'}>
          Kandidaten
        </NavigationItem>
        {isAuthenticated && (
          <NavigationItem link={'/user'} icon={'account-plus'} active={paths[1] === 'user'}>
            User
          </NavigationItem>
        )}
        <NavigationItem link={'/einstellungen'} icon={'premium-check'} active={paths[1] === 'einstellungen'}>
          Einstellungen
        </NavigationItem>
        <NavigationItem link={'/logout'} icon={'logout'}>
          Logout
        </NavigationItem>
      </div>
    </div>
  );
}
