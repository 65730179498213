import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'ag-grid-enterprise';
import type { ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames/bind';
import { MessageService, SettingsService, UserService } from '../../../services';
import { useAppSelector } from '../../../store/hooks';
import { selectUserId } from '../../../store/reducers/authReducers';
import type { Candiate, OnlyfyData } from '../../../types';
import type { Company } from '../../../types/candidate.types';
import { candidateColumns } from '../../../utils/settings/tableCandidateSettings';
import { Button } from '../../atoms';
import style from './CandidateList.module.scss';

const cx = classNames.bind(style);

type CandidateListProps = {
  candidates: Candiate[];
};

export function CandidateList({ candidates }: CandidateListProps): React.ReactElement {
  const userId = useAppSelector(selectUserId);
  const gridRef = useRef<any>();
  const messageTextArea = useRef<any>();

  const [listView, setListView] = useState(false);
  const [columnDefs, setColumnDefs] = useState(candidateColumns);
  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [onlyfyData, setOnlyfyData] = useState<OnlyfyData[]>([]);
  const [isSendMessagePopupOpen, setIsSendMessagePopupOpen] = useState(false);
  const [messageAccount, setMessageAccount] = useState(-1);
  const [message, setMessage] = useState('');
  const [isMessagesPopupOpen, setIsMessagesPopupOpen] = useState(false);
  const [messages, setMessages] = useState<{ messageText: string; sendDate: string }[]>([]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );
  const onSelectionChanged = useCallback(() => {
    setSelectedNodes(gridRef.current.api.getSelectedNodes());
  }, []);

  const openCandidates = () => {
    const urls = selectedNodes.map((node) => node.data.xingLink);

    urls.forEach((url) => {
      window.open(url, '_blank');
    });
    setTimeout(() => {
      window.focus();
    }, 1000);
  };

  const openMessagePopup = () => {
    if (onlyfyData.length === 0 && userId) {
      UserService.getOnlyfyData(userId).then((response) => {
        const onyfyData = response.data as OnlyfyData[];
        const onlyfyDataForMessage: OnlyfyData[] = [];
        const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
        const actualYear = new Date().getFullYear();
        const monthYear = `${actualMonth}-${actualYear}`;
        onyfyData.forEach((data, index) => {
          if (data.onlyfyId) {
            MessageService.getOnlyfyMessagesPerMonth(data.onlyfyId).then((res) => {
              if (res.error) {
                toast.error(`Fehler beim Laden der Anzahl der Nachrichten für den Account ${data.onlyfyUsername}.`);

                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (res.data[monthYear] && res.data[monthYear] > 999) {
                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const messageCount = res.data[monthYear] ? res.data[monthYear] : 0;
              const oData = { ...data };
              oData.onlyfyUsername = `${data.onlyfyUsername} (${messageCount})`;
              onlyfyDataForMessage.push(oData);
              if (index === onyfyData.length - 1) {
                setOnlyfyData(onlyfyDataForMessage);
                setMessageAccount(onlyfyDataForMessage[0].onlyfyId!);
              }
            });
          }
        });
      });
    }
    SettingsService.getDefaultMessage().then((response) => {
      setMessage(response.data.default_message);
      setIsSendMessagePopupOpen(true);
    });
  };

  const sendMessage = () => {
    let needSecondRequest = false;
    const date = new Date();
    const messageData = {
      message,
      contactIds: selectedNodes.map((node) => node.data.id).join(','),
      timestamp: date
        .toISOString()
        .replace('T', ' ')
        .replace(/.[0-9]{3}Z/gm, ''),
      userId: messageAccount,
    };

    const messageData2 = {
      message,
      contactIds: '',
      timestamp: date
        .toISOString()
        .replace('T', ' ')
        .replace(/.[0-9]{3}Z/gm, ''),
      userId: 0,
    };

    const onlyfyDataForMessage = onlyfyData.find((data) => data.onlyfyId === messageAccount);
    const messageCount = onlyfyDataForMessage?.onlyfyUsername?.split(' ')[1].replace('(', '').replace(')', '');

    const newCount = messageCount ? Number(messageCount) + selectedNodes.length : selectedNodes.length;
    if (newCount > 1126) {
      needSecondRequest = true;
      const contactIds = messageData.contactIds.split(',');
      const contactIds1 = contactIds.slice(newCount - 1126, contactIds.length);
      const contactIds2 = contactIds.slice(0, newCount - 1126);

      messageData.contactIds = contactIds1.join(',');
      messageData2.contactIds = contactIds2.join(',');
      const onlyfyDataForMessage2 = onlyfyData.filter((data) => data.onlyfyId !== messageAccount);
      messageData2.userId = onlyfyDataForMessage2[0].onlyfyId!;
    }

    MessageService.sendMessageRequest(messageData).then((response) => {
      if (response.error) {
        console.log(messageData);
        toast.error('Fehler beim Senden des Nachrichtensenden-Request.');

        return;
      }
      if (needSecondRequest) {
        MessageService.sendMessageRequest(messageData2).then((response2) => {
          if (response2.error) {
            console.log(messageData2);
            toast.error('Fehler beim Senden des Nachrichtensenden-Request Teil 2.');

            return;
          }
          toast.success('Nachricht erfolgreich versendet!');
          setIsSendMessagePopupOpen(false);
          setMessage('');
          needSecondRequest = false;
        });
      }
      if (!needSecondRequest) {
        toast.success('Nachricht erfolgreich versendet!');
        setIsSendMessagePopupOpen(false);
        setMessage('');
      }
    });
  };

  const getDate = (date: string) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const getActualCompany = (companies: Company[]) => {
    if (!companies) {
      return '-';
    }
    const actualCompany = companies.find((company) => company.endDate === null);

    return actualCompany?.companyName || '-';
  };

  const openMessagesPopup = (candidate: Candiate) => {
    const candidateMessages = candidate.messages.sort((a, b) => (a.sendDate > b.sendDate ? 1 : -1));

    setMessages(candidateMessages.filter((m) => !m.messageText.startsWith('#KORRESPONDENZ#')));
    setIsMessagesPopupOpen(true);
  };

  const closeMessagesPopup = () => {
    setIsMessagesPopupOpen(false);
    setMessages([]);
  };

  const addToTextArea = (textToAdd: string) => {
    if (messageTextArea && messageTextArea.current) {
      const startPosition = messageTextArea.current.selectionStart;
      const endPosition = messageTextArea.current.selectionEnd;

      setMessage(`${message.substring(0, startPosition)}${textToAdd}${message.substring(endPosition, message.length)}`);
    }
  };

  useEffect(() => {
    const indexOfMessagesColumn = candidateColumns.findIndex((column) => column.field === 'messages');
    candidateColumns[indexOfMessagesColumn].cellRenderer = (cellParams: ICellRendererParams) => {
      return (
        <div>
          <Button
            type='primary'
            disabled={cellParams.data.messages.length === 0}
            onClick={() => {
              openMessagesPopup(cellParams.data);
            }}
          >
            Nachrichten
          </Button>
        </div>
      );
    };
  }, []);

  const candidatesTableClasses = cx({ CanidadatesTable: true });

  const candidateBoxClasses = cx({ CanidadateBox: true });
  const candidateBoxImageClasses = cx({ CanidadateBoxImage: true });
  const candidateBoxTitleClasses = cx({ CanidadateBoxTitle: true });
  const candidateBoxInfosClasses = cx({ CanidadateBoxInfos: true });

  const messagesClasses = cx({ Messages: true });
  const messageClasses = cx({ Message: true });

  return (
    <>
      {isMessagesPopupOpen && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '30%',
              borderRadius: '4px',
              padding: '20px',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '2rem' }}> Nachrichten</div>
            <div className={messagesClasses}>
              {messages.map((cmessage, index) => (
                <div className={messageClasses} key={index}>
                  <div style={{ whiteSpace: 'pre-line' }}>{cmessage.messageText}</div>
                </div>
              ))}
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Button type='primary' onClick={closeMessagesPopup}>
                Schließen
              </Button>
            </div>
          </div>
        </div>
      )}
      {isSendMessagePopupOpen && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '30%',
              borderRadius: '4px',
              padding: '20px',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}> Nachricht senden</div>
            <div style={{ marginTop: '2rem' }}>
              <div style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Onlyfy-Account:</div>
              <div style={{ width: '100%', marginBottom: '2rem' }}>
                <select
                  defaultValue={messageAccount}
                  style={{ width: '100%', padding: '0.75rem', borderRadius: '4px' }}
                  onChange={(event) => setMessageAccount(Number(event.target.value))}
                >
                  {onlyfyData.map((data, index) => (
                    <option key={index} value={data.onlyfyId}>
                      {data.onlyfyUsername}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: 'flex', marginBottom: '2rem' }}>
                <span style={{ marginRight: '1rem', width: '100%' }}>
                  <Button type={'primary'} onClick={() => addToTextArea('[Candidate.name]')}>
                    Kandidatenname
                  </Button>
                </span>
                <span style={{ marginLeft: '1rem', width: '100%' }}>
                  <Button type={'primary'} onClick={() => addToTextArea('[Sender.name]')}>
                    Sendername
                  </Button>
                </span>
              </div>
              <div style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Nachricht:</div>
              <div>
                <textarea
                  ref={messageTextArea}
                  rows={30}
                  cols={60}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                ></textarea>
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Button type='primary' onClick={sendMessage}>
                Senden
              </Button>
              <Button type='secondary' onClick={() => setIsSendMessagePopupOpen(false)}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '20rem' }}>
          <Button type='primary' onClick={() => setListView(!listView)}>
            {listView ? 'Tabelle' : 'Liste'}
          </Button>
        </div>
        {!listView && (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '20rem', marginRight: '1rem' }}>
                <Button type='primary' onClick={() => openCandidates()} disabled={selectedNodes.length === 0}>
                  Kandidaten öffnen
                </Button>
              </div>
              <div style={{ width: '20rem' }}>
                <Button type='primary' onClick={() => openMessagePopup()} disabled={selectedNodes.length === 0}>
                  Nachricht senden
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {!listView && (
        <div style={{ marginBottom: '1rem', fontSize: '1.3rem', fontWeight: 'bolder' }}>
          <div>Aktuell ausgewählte: {selectedNodes.length} Kandidaten</div>
        </div>
      )}
      {!listView && (
        <div className={candidatesTableClasses}>
          <div className='ag-theme-alpine' style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={candidates} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection={'multiple'}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      )}
      {listView && (
        <div>
          {candidates &&
            candidates.map((candidate, index) => (
              <div
                key={index}
                className={candidateBoxClasses}
                onClick={() => window.open(candidate.xingLink, '_blank')}
              >
                <div>
                  <span className={candidateBoxImageClasses}>
                    {candidate.profileImageUrl &&
                      candidate.profileImageUrl !== '' &&
                      candidate.profileImageUrl !== null && <img src={candidate.profileImageUrl} alt='' />}
                    {(!candidate.profileImageUrl ||
                      candidate.profileImageUrl === '' ||
                      candidate.profileImageUrl === null) &&
                      '📸'}
                  </span>
                </div>
                <div>
                  <div className={candidateBoxTitleClasses}>
                    <span>{`${candidate.firstName} ${candidate.lastName}`}</span>
                    <span>{candidate.position}</span>
                    <span>{candidate.fieldOfActivity}</span>
                    <span>{candidate.careerLevel}</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className={candidateBoxInfosClasses}>
                      <span>📞 {candidate.contactData?.phone}</span>
                      <span>📧 {candidate.contactData?.email}</span>
                      <span>📫 {candidate.contactData?.address}</span>
                    </div>
                    <div className={candidateBoxInfosClasses}>
                      <span>🇱 {candidate.country}</span>
                      <span>🗣️ {candidate.languages}</span>
                    </div>
                    <div className={candidateBoxInfosClasses}>
                      <span>🏢 {getActualCompany(candidate.companies)}</span>
                      <span>🕘️ {getDate(candidate.positionSince)}</span>
                      <span>⚧️️ {candidate.occupationType}</span>
                    </div>
                    <div className={candidateBoxInfosClasses}>
                      <span>
                        <b>Ich biete:</b>
                      </span>
                      <span>{candidate.iOffer}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
}
