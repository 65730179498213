import type { ReactNode } from 'react';
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ProtectedPage } from '../components/atoms';
import {
  Dashboard,
  Einstellungen,
  Kandidaten,
  Login,
  Logout,
  Messages,
  Nachrichtenauftraege,
  Onlyfy,
  Projekt,
  Projekte,
  Suche,
  Suchen,
  User,
} from '../components/pages';

type RouterProps = {
  children?: ReactNode;
};
export function Router({ children }: RouterProps): JSX.Element {
  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route
          path='dashboard'
          element={
            <ProtectedPage>
              <Dashboard />
            </ProtectedPage>
          }
        />
        <Route
          path='suchen'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Suchen />} />
          <Route path=':searchId' element={<Suche />} />
        </Route>
        <Route
          path='projekte'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Projekte />} />
          <Route path=':projectId' element={<Projekt />} />
        </Route>
        <Route
          path='kandidaten'
          element={
            <ProtectedPage>
              <Kandidaten />
            </ProtectedPage>
          }
        />
        <Route
          path='nachrichten'
          element={
            <ProtectedPage>
              <Messages />
            </ProtectedPage>
          }
        />
        <Route
          path='nachrichtenauftraege'
          element={
            <ProtectedPage>
              <Nachrichtenauftraege />
            </ProtectedPage>
          }
        />
        <Route
          path='user'
          element={
            <ProtectedPage>
              <User />
            </ProtectedPage>
          }
        />
        <Route
          path='einstellungen'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Einstellungen />} />
          <Route path='onlyfy' element={<Onlyfy />} />
        </Route>
        <Route
          path='logout'
          element={
            <ProtectedPage>
              <Logout />
            </ProtectedPage>
          }
        />
        <Route path='login' element={<Login />} />
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<h1>Actual no Page</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
