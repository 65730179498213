import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { MessageService, SettingsService } from '../../../services';
import type { MessageSendRequest } from '../../../types';
import { Button, Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Nachrichtenauftraege.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Nachrichtenauftraege(): JSX.Element {
  const navigate = useNavigate();

  const [sendRequests, setSendRequests] = useState<MessageSendRequest[]>([]);
  const [isSenderActive, setIsSenderActive] = useState<boolean>(false);

  const getCreatedAt = (createdAt: string) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  const getSenderStatus = () => {
    SettingsService.getSendMessageStatus().then((response) => {
      if (response.error) {
        toast.error('Senderstatus konnten nicht geladen werden!');

        return;
      }
      setIsSenderActive(response.data.enableSender);
    });
  };

  const updateSender = () => {
    SettingsService.updateSendMessageStatus(!isSenderActive).then((response) => {
      if (response.error) {
        toast.error('Aktualisierung des Senders fehlgeschlagen');

        return;
      }

      toast.success('Sender aktualisiert');
      getSenderStatus();
    });
  };

  useEffect(() => {
    MessageService.getOpenSendRequests().then((response) => {
      if (response.error) {
        toast.error('Offene Nachrichtenaufträge konnten nicht geladen werden!');

        return;
      }

      setSendRequests(response.data);
    });
    getSenderStatus();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const messageBoxClasses = cx({ MessageBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses} test-id='messages-header'>
          <Icon icon='clock' size={70}></Icon>
          <span>
            <h1>Nachrichtenaufträge</h1>
            <div>Anzahl: {sendRequests.length}</div>
          </span>
        </div>
        <div style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: '1.5rem' }}>
            Aktueller Status: {isSenderActive ? 'Nachrichten werden versand.' : 'Nachrichten werden nicht versand!'}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20rem' }}>
              <Button type='primary' onClick={() => updateSender()}>
                Senden {isSenderActive ? 'stoppen' : 'starten'}
              </Button>
            </div>
          </div>
        </div>
        {sendRequests.map((sendRequest) => (
          <div key={sendRequest.id} test-id={`send-request-entry-${sendRequest.id}`} className={messageBoxClasses}>
            <div>
              <span>{getCreatedAt(sendRequest.timestamp)}</span>
              <span>
                <span style={{ marginLeft: '1rem' }}>{sendRequest.isSend ? '✅' : '⌛'}</span>
              </span>
            </div>
            <div>Auftragsnummer: {sendRequest.id}</div>
            <div></div>
          </div>
        ))}
      </div>
    </div>
  );
}
