import React from 'react';
import classNames from 'classnames/bind';
import type { IconTypes } from '../../atoms';
import { Icon, Link } from '../../atoms';
import style from './NavigationItem.module.scss';

const cx = classNames.bind(style);

type NavigationItemProps = {
  link: string;
  children: React.ReactNode;
  icon: IconTypes;
  active?: boolean;
};

export function NavigationItem({ link, children, icon, active }: NavigationItemProps): JSX.Element {
  const navigationItemClasses = cx({ NavigationItem: true });
  const linkClasses = cx({ NavigationItemLink: true, NavigationItemLink__active: active });

  return (
    <div className={navigationItemClasses} test-id={`navigation-item${link.replace('/', '-')}`}>
      <Icon icon={icon} size={22} color={active ? '#1b3975' : '#000000'} />
      <Link href={link} className={linkClasses}>
        {children}
      </Link>
    </div>
  );
}
