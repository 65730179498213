import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'ag-grid-enterprise';
import classNames from 'classnames/bind';
import { ProjectService } from '../../../../services';
import type { Candiate, Project } from '../../../../types';
import { Icon, Text } from '../../../atoms';
import { NavigationDrawer } from '../../../organisms';
import { CandidateList } from '../../../organisms/CandidateList/CandidateList';
import style from './Projekt.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Projekt(): JSX.Element {
  const params = useParams();

  const [project, setProject] = useState<Project>();
  const [candiates, setCandiates] = useState<Candiate[]>([]);

  const getCandidates = () => {
    ProjectService.getAllCandidatesByProjectId(Number(params.projectId)).then((response) => {
      if (response.error) {
        toast.error('Kandidaten konnten nicht geladen werden!');

        return;
      }
      setCandiates(response.data);
    });
  };

  const getCreatedAt = (createdAt: string) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    ProjectService.getProjectById(Number(params.projectId)).then((response) => {
      if (response.error) {
        toast.error('Projekt konnte nicht geladen werden!');

        return;
      }
      setProject(response.data);
    });

    getCandidates();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const boxHeaderClasses = cx({ BoxHeader: true });
  const boxInnerHeaderClasses = cx({ BoxInnerHeader: true });
  const projectBoxClasses = cx({ SucheBox: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='suche-header'>
            <Icon icon='magnify' size={70}></Icon>
            <span>
              <h1>Projekt</h1>
              <div>Anzahl Kandidaten: {candiates?.length}</div>
            </span>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 className={boxHeaderClasses} test-id='suche-subheader-suche'>
                Projekt
              </h2>
            </div>
            {project && (
              <div className={projectBoxClasses}>
                <div>
                  <span>{getCreatedAt(project.createdAt)}</span>
                </div>

                <div className={boxInnerHeaderClasses}>Name des Projektes:</div>
                <div>
                  <Text>{project.name}</Text>
                </div>

                <div className={boxInnerHeaderClasses}>Beschreibung des Projektes:</div>
                <div>
                  <Text>{project.description}</Text>
                </div>
              </div>
            )}
          </div>

          <div style={{ height: '100%' }}>
            <h2 className={boxHeaderClasses} test-id='suche-subheader-candidates'>
              Kandidaten
            </h2>
            <CandidateList candidates={candiates} />
          </div>
        </div>
      </div>
    </>
  );
}
