import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { MessageService, UserService } from '../../../../services';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserId } from '../../../../store/reducers/authReducers';
import type { OnlyfyData } from '../../../../types';
import { Button, Icon } from '../../../atoms';
import { Input } from '../../../molecules';
import { NavigationDrawer } from '../../../organisms';
import style from './Onlyfy.module.scss';

const cx = classNames.bind(style);

export function Onlyfy(): JSX.Element {
  const userId = useAppSelector(selectUserId);

  const [onlyfyData, setOnlyfyData] = useState<OnlyfyData[]>([]);
  const [onlyfyDataMessages, setOnlyfyDataMessages] = useState<Map<string, number>>(new Map());
  const [newOnlyfyData, setNewOnlyfyData] = useState<OnlyfyData | undefined>(undefined);

  const getOnlyfyData = () => {
    if (userId) {
      UserService.getOnlyfyData(userId).then((response) => {
        if (response.error) {
          toast.error('Etwas ist schief gelaufen. Bitte ausloggen und erneut einloggen.');

          return;
        }
        setOnlyfyData(response.data);
        const oData = response.data as OnlyfyData[];

        const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
        const actualYear = new Date().getFullYear();
        const monthYear = `${actualMonth}-${actualYear}`;
        const map = new Map<string, number>();
        oData.forEach((data, index) => {
          if (data.onlyfyId) {
            MessageService.getOnlyfyMessagesPerMonth(data.onlyfyId).then((res) => {
              if (res.error) {
                toast.error(`Fehler beim Laden der Anzahl der Nachrichten für den Account ${data.onlyfyUsername}.`);

                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const messageCount = res.data[monthYear] ? res.data[monthYear] : 0;
              map.set(data.onlyfyUsername, messageCount);
              if (index === oData.length - 1) {
                setOnlyfyDataMessages(map);
              }
            });
          }
        });
      });
    }
  };

  const updateOnlyfy = (data: OnlyfyData) => {
    UserService.updateOnlyfyData({ userId, onlyfyDataId: data.onlyfyId?.toString(), ...data }).then((response) => {
      if (response.error) {
        toast.error('Fehler beim Speichern der Onlyfy-Daten.');

        return;
      }
      toast.success('Onlyfy-Daten erfolgreich geupdated.');
    });
  };

  const updateUsername = (value: string, index: number) => {
    const data = [...onlyfyData];
    data[index].onlyfyUsername = value;
    setOnlyfyData(data);
  };

  const updateUPasswort = (value: string, index: number) => {
    const data = [...onlyfyData];
    data[index].onlyfyPassword = value;
    setOnlyfyData(data);
  };

  const updateDisplayName = (value: string, index: number) => {
    const data = [...onlyfyData];
    data[index].onlyfyDisplayName = value;
    setOnlyfyData(data);
  };

  const openCreateOnlyfy = () => {
    setNewOnlyfyData({ onlyfyUsername: '', onlyfyPassword: '', onlyfyDisplayName: '' });
  };

  const saveNewOnlyfy = async () => {
    if (newOnlyfyData && newOnlyfyData.onlyfyUsername && newOnlyfyData.onlyfyPassword) {
      const newOnlyfyResponse = await UserService.createOnlyfyData({ userId, ...newOnlyfyData });
      if (newOnlyfyResponse.error) {
        console.log(newOnlyfyResponse);
        toast.error('Onlyfy-Daten konnte nicht erstellt werden!');

        return;
      }
      setNewOnlyfyData(undefined);
      toast.success('Onlyfy-Daten erfolgreich erstellt!');
      getOnlyfyData();
    } else {
      toast.error('Alle Felder müssen ausgefüllt sein.');
    }
  };

  const abortNewOnlyfy = () => {
    setNewOnlyfyData(undefined);
  };

  useEffect(() => {
    getOnlyfyData();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');

  return (
    <>
      {newOnlyfyData && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '30%',
              borderRadius: '4px',
              padding: '20px',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}> Onlyfy erstellen</div>
            <div style={{ marginTop: '2rem', width: '80%' }}>
              <div>Username:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='text'
                  placeholder='User'
                  onChange={(event) => {
                    newOnlyfyData.onlyfyUsername = event.target.value;
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: '2rem', width: '80%' }}>
              <div>Passwort:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='password'
                  placeholder='Passwort'
                  onChange={(event) => {
                    newOnlyfyData.onlyfyPassword = event.target.value;
                  }}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: '2rem', width: '80%' }}>
              <div>Anzeigename:</div>
              <div>
                <Input
                  icon={'account-plus'}
                  type='text'
                  placeholder='Anzeigename'
                  onChange={(event) => {
                    newOnlyfyData.onlyfyDisplayName = event.target.value;
                  }}
                ></Input>
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Button type='primary' onClick={saveNewOnlyfy}>
                Speichern
              </Button>
              <Button type='secondary' onClick={abortNewOnlyfy}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='onlyfy-einstellungen-header'>
            <Icon icon='account-plus' size={70}></Icon>
            <span>
              <h1>Onlyfy-Einstellungen</h1>
            </span>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {onlyfyData.map((data, index) => (
              <div
                key={index}
                style={{
                  flex: 1,
                  minWidth: 'calc(100% / 3 - 4rem)',
                  maxWidth: 'calc(100% / 3 - 4rem)',
                  backgroundColor: '#fff',
                  marginRight: '1rem',
                  marginBottom: '1rem',
                  padding: '15px 15px',
                  borderRadius: '4px',
                  boxShadow: '2px 2px 4px 0px #d9d9d9',
                }}
              >
                <div>
                  <h2 style={{ marginBottom: '1.5rem' }}>
                    Onlyfy-Daten ({onlyfyDataMessages.get(data.onlyfyUsername)})
                  </h2>
                </div>

                <div>
                  <div
                    style={{
                      margin: '1rem 1rem 1rem 0',
                      fontSize: '1.6rem',
                      fontWeight: '400',
                      color: '#666666',
                    }}
                  >
                    User
                  </div>
                  <div>
                    <Input
                      onChange={(event) => updateUsername(event.target.value, index)}
                      icon='magnify'
                      type='text'
                      placeholder='User'
                      defaultValue={data.onlyfyUsername}
                    ></Input>
                  </div>
                </div>
                <div style={{ marginTop: '3rem' }}>
                  <div
                    style={{
                      margin: '1rem 1rem 1rem 0',
                      fontSize: '1.6rem',
                      fontWeight: '400',
                      color: '#666666',
                    }}
                  >
                    Passwort
                  </div>
                  <div>
                    <Input
                      onChange={(event) => updateUPasswort(event.target.value, index)}
                      icon='magnify'
                      type='password'
                      placeholder='Passwort'
                      defaultValue={data.onlyfyPassword}
                    ></Input>
                  </div>
                </div>

                <div style={{ marginTop: '3rem' }}>
                  <div
                    style={{
                      margin: '1rem 1rem 1rem 0',
                      fontSize: '1.6rem',
                      fontWeight: '400',
                      color: '#666666',
                    }}
                  >
                    Anzeigename
                  </div>
                  <div>
                    <Input
                      onChange={(event) => updateDisplayName(event.target.value, index)}
                      icon='magnify'
                      type='text'
                      placeholder='Max Mustermann'
                      defaultValue={data.onlyfyDisplayName}
                    ></Input>
                  </div>
                </div>

                <div style={{ marginTop: '3rem', width: '20rem' }}>
                  <Button type='primary' onClick={() => updateOnlyfy(data)}>
                    Speichern
                  </Button>
                </div>
              </div>
            ))}
            <div
              style={{
                flex: 1,
                minWidth: 'calc(100% / 3 - 4.5rem)',
                maxWidth: 'calc(100% / 3 - 4.5rem)',
                marginRight: '1rem',
                marginBottom: '1rem',
                padding: '15px 15px',
                borderRadius: '4px',
                border: '2px solid #1b3975',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                cursor: 'pointer',
              }}
              onClick={openCreateOnlyfy}
            >
              <div style={{ fontSize: '20rem', color: '#1b3975', textAlign: 'center' }}>+</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
