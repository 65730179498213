import type { ICellRendererParams } from 'ag-grid-community';
import type { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

const getDate = (date: string) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();

  return `${day}.${month}.${year}`;
};
export const candidateColumns: ColDef<any, any>[] = [
  {
    field: 'firstName',
    headerName: 'Vorname',
    filter: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    // cellRenderer: (cellParams: ICellRendererParams) => `${cellParams.data.firstName} ${cellParams.data.lastName}`,
  },
  {
    field: 'lastName',
    headerName: 'Nachname',
    filter: true,
    // cellRenderer: (cellParams: ICellRendererParams) => `${cellParams.data.firstName} ${cellParams.data.lastName}`,
  },
  {
    field: 'messages',
    headerName: 'Letzte Nachrichten',
    filter: false,
    cellRenderer: (cellParams: ICellRendererParams) => `Button`,
  },
  {
    field: '',
    headerName: 'Anzahl Nachrichten',
    filter: 'agNumberColumnFilter',
    valueGetter: (params: any) => Number(params.data.messages.length),
  },
  { field: 'position', headerName: 'Derzeitige Position', filter: true },
  {
    field: '',
    headerName: 'Dauer Position',
    filter: true,
    valueGetter: (cellParams) => {
      const { positionSince } = cellParams.data;
      if (!positionSince) {
        return '-';
      }
      const startDate = new Date(positionSince);
      const endDate = new Date();
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return `${diffDays} Tage`;
    },
  },
  {
    field: '',
    headerName: 'Aktueller Arbeitgeber',
    filter: true,
    valueGetter: (cellParams: any) => {
      const { companies } = cellParams.data;
      if (!companies) {
        return '-';
      }
      const actualCompany = companies.find((company: any) => company.endDate === null);

      return actualCompany?.companyName;
    },
  },
  {
    field: '',
    headerName: 'Letzter Arbeitgeber',
    filter: true,
    valueGetter: (cellParams: any) => {
      const { companies } = cellParams.data;
      if (!companies) {
        return '-';
      }

      return companies[1] ? companies[1].companyName : '-';
    },
  },
  { field: 'lastPosition', headerName: 'Letzte Position', filter: true },
  { field: 'occupationType', headerName: 'Beschäftigungsart', filter: true },
  { field: 'iOffer', headerName: 'Ich biete', filter: true },
  { field: 'preferredFieldOfActivity', headerName: 'Bevorzugtes Tätigkeitsfeld', filter: true },
  { field: 'languages', headerName: 'Sprache', filter: true },
  {
    field: '',
    headerName: 'Letzte Nachricht',
    filter: true,
    valueGetter: (cellParams: any) =>
      cellParams.data.messages.length > 0
        ? getDate(
            cellParams.data.messages.sort(
              (a: { messageText: string; sendDate: string }, b: { messageText: string; sendDate: string }) =>
                a.sendDate > b.sendDate ? 1 : -1
            )[cellParams.data.messages.length - 1].sendDate
          )
        : '-',
  },

  { field: 'contactData.address', headerName: 'Kontaktdaten', filter: true },
];
