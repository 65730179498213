import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'ag-grid-enterprise';
import classNames from 'classnames/bind';
import { CandidateService, SuchenService } from '../../../../services';
import type { Candiate, Suche } from '../../../../types';
import { Icon, Text } from '../../../atoms';
import { NavigationDrawer } from '../../../organisms';
import { CandidateList } from '../../../organisms/CandidateList/CandidateList';
import style from './Suche.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Suche(): JSX.Element {
  const params = useParams();

  const [suche, setSuche] = useState<Suche>();
  const [candiates, setCandiates] = useState<Candiate[]>([]);

  const getCandidates = () => {
    CandidateService.getAllCandidatesBySearchId(Number(params.searchId)).then((response) => {
      if (response.error) {
        toast.error('Kandidaten konnten nicht geladen werden!');

        return;
      }
      setCandiates(response.data);
    });
  };

  const getCreatedAt = (createdAt: string) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  const toggleSearch = () => {
    if (suche) {
      if (suche.isFinal) {
        SuchenService.reopenSearch(suche.id).then((response) => {
          if (response.error) {
            toast.error('Suche konnte nicht geöffnet werden!');

            return;
          }
          const newSearch = { ...suche, isFinal: false };
          setSuche(newSearch);
        });
      }
      if (!suche.isFinal) {
        SuchenService.finishSearch(suche.id).then((response) => {
          if (response.error) {
            toast.error('Suche konnte nicht geschlossen werden!');

            return;
          }
          const newSearch = { ...suche, isFinal: true };
          setSuche(newSearch);
        });
      }
    }
  };

  useEffect(() => {
    SuchenService.getSucheById(Number(params.searchId)).then((response) => {
      if (response.error) {
        toast.error('Suche konnten nicht geladen werden!');

        return;
      }
      setSuche(response.data);
    });

    getCandidates();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const boxHeaderClasses = cx({ BoxHeader: true });
  const boxInnerHeaderClasses = cx({ BoxInnerHeader: true });
  const sucheBoxClasses = cx({ SucheBox: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='suche-header'>
            <Icon icon='magnify' size={70}></Icon>
            <span>
              <h1>Suche</h1>
              <div>Anzahl Kandidaten: {candiates?.length}</div>
            </span>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 className={boxHeaderClasses} test-id='suche-subheader-suche'>
                Suche
              </h2>
              <span>
                {suche && (
                  <span
                    style={{ fontSize: '2rem', marginRight: '1rem', cursor: 'pointer' }}
                    onClick={() => toggleSearch()}
                  >
                    {suche.isFinal ? '🔁' : '🛑'}
                  </span>
                )}
              </span>
            </div>
            {suche && (
              <div className={sucheBoxClasses}>
                <div>
                  <span>{getCreatedAt(suche.createdAt)}</span>
                  <span>
                    <span style={{ marginLeft: '1rem' }}>{suche.isFinal ? '✅' : '⌛'}</span>
                  </span>
                </div>

                <div className={boxInnerHeaderClasses}>Name der Suche:</div>
                <div>
                  <Text>{suche.name}</Text>
                </div>
              </div>
            )}
          </div>

          <div style={{ height: '100%' }}>
            <h2 className={boxHeaderClasses} test-id='suche-subheader-candidates'>
              Kandidaten
            </h2>
            <CandidateList candidates={candiates} />
          </div>
        </div>
      </div>
    </>
  );
}
