import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { MessageService } from '../../../services';
import { useAppSelector } from '../../../store/hooks';
import { selectUserId } from '../../../store/reducers/authReducers';
import type { UnreadMessage } from '../../../types';
import { Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Messages.module.scss';

const cx = classNames.bind(style);

export function Messages(): JSX.Element {
  const userId = useAppSelector(selectUserId);

  const [unreadMessages, setUnreadMessages] = useState<UnreadMessage[]>([]);
  const getSendDate = (sendDate: string) => {
    const date = new Date(sendDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  const getUnreadMessages = () => {
    if (userId) {
      MessageService.getUnreadMessages(userId).then((response) => {
        if (response.error) {
          console.error('Nachrichten konnten nicht geladen werden!');

          return;
        }
        const { data } = response;
        setUnreadMessages(data as UnreadMessage[]);
      });
    }
  };

  const markCandidateMessagesAsRead = (candidateId: number) => {
    MessageService.markCandidateMessagesAsRead(candidateId).then((response) => {
      if (response.error) {
        console.error('Nachrichten konnten nicht als gelesen markiert werden!');

        return;
      }
      getUnreadMessages();
    });
  };

  useEffect(() => {
    getUnreadMessages();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const messageBoxClasses = cx({ MessageBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses} test-id='ungelesene-nachrichten-header'>
          <Icon icon='document' size={70}></Icon>
          <span>
            <h1>Ungelesene Nachrichten</h1>
            <div>Anzahl: {unreadMessages.length}</div>
          </span>
        </div>
        {unreadMessages.map((message) => (
          <div key={message.id} test-id={`unread-message-entry-${message.id}`} className={messageBoxClasses}>
            <div>
              <span>{getSendDate(message.sendDate)}</span>
              <span>
                Kandidat: {message.firstName} {message.lastName} - OnlyfyUser: {message.onlyfyUserId}
              </span>
              <span>
                <span
                  style={{ marginLeft: '1rem', cursor: 'pointer' }}
                  onClick={() => markCandidateMessagesAsRead(message.candidateId)}
                >
                  Gelesen markieren: 👀
                </span>
              </span>
            </div>
            <div style={{ whiteSpace: 'pre-wrap' }}>{message.messageText}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
