import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { logout } from '../../../store/reducers';

export function Logout(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('jwt');

    dispatch(logout());
    navigate('/login', { replace: true });
  }, []);

  return <></>;
}
