import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { ProjectService } from '../../../services';
import type { Project } from '../../../types';
import { Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Projekte.module.scss';

const cx = classNames.bind(style);

export function Projekte(): JSX.Element {
  const navigation = useNavigate();
  const [project, setProject] = useState<Project[]>([]);

  const getAllProjects = () => {
    ProjectService.getAllProjects().then((response) => {
      if (response.error) {
        console.log(response);
        toast.error('Projekte konnten nicht geladen werden!');

        return;
      }
      setProject(response.data);
    });
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const projectClasses = cx({ Project: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses} test-id='user-header'>
            <Icon icon='magnify' size={70}></Icon>
            <span>
              <h1>Projekte</h1>
              <div>Anzahl Projekte: {project.length}</div>
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            {project.map((p) => (
              <div
                key={p.id}
                test-id={`project-entry-${p.id}`}
                className={projectClasses}
                onClick={() => navigation(`/projekte/${p.id}`)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
                  <span style={{ fontWeight: 'bold' }}>{p.name}</span>
                </div>

                <div style={{ color: '#7f7f7f', fontSize: '12px', margin: '5px 0 0 0', lineHeight: '1.5' }}>
                  <span style={{ fontWeight: 'bold' }}>{p.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
