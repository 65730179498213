import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { SuchenService } from '../../../services';
import type { Suche } from '../../../types';
import { Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Suchen.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Suchen(): JSX.Element {
  const navigate = useNavigate();

  const [suchen, setSuchen] = useState<Suche[]>([]);

  const getCreatedAt = (createdAt: string) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    SuchenService.getAllSuchen().then((response) => {
      if (response.error) {
        toast.error('Suchen konnten nicht geladen werden!');

        return;
      }
      const data = response.data as Suche[];
      if (data) {
        data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      }
      setSuchen(data);
    });
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const sucheBoxClasses = cx({ SucheBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses} test-id='suchen-header'>
          <Icon icon='magnify' size={70}></Icon>
          <span>
            <h1>Suchen</h1>
            <div>Anzahl: {suchen.length}</div>
          </span>
        </div>
        {suchen.map((suche) => (
          <div
            key={suche.id}
            test-id={`suchen-suche-entry-${suche.id}`}
            className={sucheBoxClasses}
            onClick={() => navigate(`/suchen/${suche.id}`)}
          >
            <div>
              <span>{getCreatedAt(suche.createdAt)}</span>
              <span>
                <span style={{ marginLeft: '1rem' }}>{suche.isFinal ? '✅' : '⌛'}</span>
              </span>
            </div>
            <div>{suche.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
